const dev = {
  STRIPE_KEY: "pk_test_VabSsM0WVfHqXYI3LilpxNpJ",
  s3: {
    REGION: "us-east-1",
    BUCKET: "notes-app-2-api-dev-attachmentsbucket-hp1imuoqfzm"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://ecd7989mdf.execute-api.us-east-1.amazonaws.com/dev"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_jHMO4FUMp",
    APP_CLIENT_ID: "7smslmkrej78jentp08p3d87rt",
    IDENTITY_POOL_ID: "us-east-1:c80079aa-4ed1-492b-b1b7-118a6ef49158"
  }
};

const prod = {
  STRIPE_KEY: "pk_test_VabSsM0WVfHqXYI3LilpxNpJ",
  s3: {
    REGION: "us-east-1",
    BUCKET: "notes-app-2-api-prod-attachmentsbucket-19sri5ri57jnc"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://1vovqb9qj8.execute-api.us-east-1.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_hVgDEHojz",
    APP_CLIENT_ID: "6bbn6f8nn671n16fe6l96oeocg",
    IDENTITY_POOL_ID: "us-east-1:5663327e-4458-4705-ae42-953f67cd9167"
  }
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};